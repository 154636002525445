import { graphql } from "gatsby";
import { ReactElement } from "react";

import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import Quoting from "../../../features/quotes-funnel/ui/pages/quoting/Quoting";
import * as styles from "../../../features/quotes-funnel/ui/pages/quoting/Quoting.module.scss";
import { PageId } from "../../../settings/pages";

const Page = (): ReactElement => <Quoting />;

export default withFunnelWrapper(Page, styles.quoting, PageId.quoting);

export const query = graphql`
  query QuotingPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
